import {
  NEXT_PUBLIC_CANONICAL_DOMAIN_NAME,
  NEXT_PUBLIC_USE_BASELINE_SEO_DEFAULT,
} from "./constants/public-constant";

// These provide a default, but shall be overridden in EVERY page
const DEFAULT = {
  title: "Virtual Fes 2023 香港站",
  description:
    "feat. 米亞Mya、MK妹、如月ルミィ、月島クロス，Alban Knox、Ike Eveland、Shu Yamino、Sonny Brisko from Nijisanji EN等，將於7月22日九龍灣國際展貿中心舉行，門票及官方週邊商品現已公開發售，另設同步線上直播票，讓你安在家也能欣賞精彩演出。當日直播室請透過本站進入。",
};

const baselineConfig = {
  titleTemplate: "%s",
  canonical: `https://${NEXT_PUBLIC_CANONICAL_DOMAIN_NAME}`,
  keywords:
    "Virtual Fes,Alban Knox,Ike Eveland,Shu Yamino,Sonny Brisko,米亞Mya,MK妹,如月ルミィ,月島クロス,Nijisanji EN,彩虹社,Flying Milk Tea,飛天奶茶,Live,Streaming,直播,演唱會,虛擬直播主",
  openGraph: {
    url: `https://${NEXT_PUBLIC_CANONICAL_DOMAIN_NAME}`,
    type: "website",
    site_name: "Virtual Fes",
    title: DEFAULT.title,
    description: DEFAULT.description,
    locale: "zh_HK",
    images: [
      {
        url: "/seo/banner.png",
        width: 1200,
        height: 614,
        alt: "Virtual Fes",
        type: "image/png",
      },
      {
        url: "/seo/banner-m.png",
        width: 750,
        height: 938,
        alt: "Virtual Fes",
        type: "image/png",
      },
    ],
  },
  additionalLinkTags: [
    {
      rel: "icon",
      href: "/favicon.ico",
    },
    {
      rel: "icon",
      href: "/apple-touch-icons.ico",
      sizes: "180x180",
    },
  ],
  twitter: {
    handle: "@virtualfes2023",
    site: "@virtualfes2023",
    cardType: "summary_large_image",
  },
};

const USE_BASELINE_SEO_DEFAULT = NEXT_PUBLIC_USE_BASELINE_SEO_DEFAULT;

const defaultConfig = USE_BASELINE_SEO_DEFAULT
  ? {
      ...baselineConfig,
      dangerouslySetAllPagesToNoIndex:
        baselineConfig.canonical !== "https://virtualfes2023.flyingmilktea.com",
      defaultTitle: DEFAULT.title,
      description: DEFAULT.description,
    }
  : {};

export const SeoBaselineConfig = baselineConfig;
export const SeoDefaultConfig = defaultConfig;

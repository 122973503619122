import React, { useEffect, useMemo, useState } from "react";

import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter, withRouter } from "next/router";
import Script from "next/script";

import { CssBaseline, ThemeProvider } from "@mui/material";

import { CacheProvider } from "@emotion/react";
import "lazysizes";
import {
  AmplitudeProvider,
  AuthProvider,
  GraphQLClientContext,
  RegionProvider,
} from "market-webapp-commons";
import { DefaultSeo } from "next-seo";

import {
  NEXT_PUBLIC_AMPLITUDE_KEY,
  NEXT_PUBLIC_GADS_ID,
  NEXT_PUBLIC_GTAG_ID,
} from "../constants/public-constant";
import { PageLocaleProvider } from "../contexts/PageLocaleContext";
import { SettingsProvider } from "../contexts/SettingsContext";
import createEmotionCache from "../createEmotionCache";
import GraphQLClient from "../graphql-client";
import useSettings from "../hooks/useSettings";
import { SeoDefaultConfig } from "../seo";
import createCustomTheme from "../theme";

const Toaster = dynamic(() => import("react-hot-toast").then((m) => m.Toaster));
const RoutedAuthProvider = withRouter(AuthProvider);

// https://github.com/mui-org/material-ui/tree/285e72875b9e7c9d6fc7303ccd54f0db16a4160d/examples/nextjs/
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag("config", NEXT_PUBLIC_GTAG_ID, {
        page_path: url,
      });
      window.gtag("config", NEXT_PUBLIC_GADS_ID, {
        page_path: url,
      });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const { settings } = useSettings();
  const graphqlClientContextValue = useMemo(
    () => ({ client: GraphQLClient }),
    []
  );

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });
  const getLayout = Component.getLayout ?? ((page) => page);

  if (!pageProps?.locale) {
    throw new Error(`Missing locale in pageProps: ${router.asPath}`);
  }

  const [amplitude, setAmplitude] = useState();
  useEffect(() => {
    const am = new Promise((resolve) => {
      const fn = async () => {
        const a = (
          await import("amplitude-js").then((m) => m.default)
        ).getInstance();
        a.init(NEXT_PUBLIC_AMPLITUDE_KEY, undefined, {
          batchEvents: false,
          forceHttps: process.env.NODE_ENV === "development",
          includeGclid: true,
          includeReferrer: true,
          includeUtm: true,
          logLevel: process.env.NODE_ENV === "development" ? "INFO" : "DISABLE",
        });
        resolve(a);
      };
      fn();
    });
    setAmplitude(am);
  }, []);

  return (
    <AmplitudeProvider amplitude={amplitude}>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
        </Head>
        <Script
          async
          // google tag manager
          // https://nextjs.org/docs/messages/next-script-for-ga
          src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GTAG_ID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${NEXT_PUBLIC_GTAG_ID}");
          gtag('config', "${NEXT_PUBLIC_GADS_ID}");
        `}
        </Script>
        <PageLocaleProvider locale={pageProps.locale}>
          <DefaultSeo {...SeoDefaultConfig} />
          <GraphQLClientContext.Provider value={graphqlClientContextValue}>
            <RoutedAuthProvider>
              <SettingsProvider>
                <RegionProvider>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {getLayout(<Component {...pageProps} />)}
                    <Toaster
                      position="bottom-left"
                      reverseOrder
                      toastOptions={{
                        style: {
                          borderRadius: "2px",
                          boxShadow:
                            "0 2px 5px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 2.5%)",
                        },
                      }}
                    />
                  </ThemeProvider>
                </RegionProvider>
              </SettingsProvider>
            </RoutedAuthProvider>
          </GraphQLClientContext.Provider>
        </PageLocaleProvider>
      </CacheProvider>
    </AmplitudeProvider>
  );
};

export default App;

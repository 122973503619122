import getConfig from "next/config";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export const NEXT_PUBLIC_AMPLITUDE_KEY =
  publicRuntimeConfig?.NEXT_PUBLIC_AMPLITUDE_KEY ??
  serverRuntimeConfig?.NEXT_PUBLIC_AMPLITUDE_KEY ??
  "";

export const NEXT_PUBLIC_CANONICAL_DOMAIN_NAME =
  publicRuntimeConfig?.NEXT_PUBLIC_CANONICAL_DOMAIN_NAME ??
  serverRuntimeConfig?.NEXT_PUBLIC_CANONICAL_DOMAIN_NAME ??
  null;

export const NEXT_PUBLIC_DOMAIN_NAME =
  publicRuntimeConfig?.NEXT_PUBLIC_DOMAIN_NAME ??
  serverRuntimeConfig?.NEXT_PUBLIC_DOMAIN_NAME ??
  null;

export const NEXT_PUBLIC_GADS_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_GADS_ID ??
  serverRuntimeConfig?.NEXT_PUBLIC_GADS_ID ??
  "";

export const NEXT_PUBLIC_GRAPHQL_URL =
  publicRuntimeConfig?.NEXT_PUBLIC_GRAPHQL_URL ??
  serverRuntimeConfig?.NEXT_PUBLIC_GRAPHQL_URL ??
  null;

export const NEXT_PUBLIC_GTAG_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_GTAG_ID ??
  serverRuntimeConfig?.NEXT_PUBLIC_GTAG_ID ??
  "";

export const NEXT_PUBLIC_NODE_ENV =
  publicRuntimeConfig?.NEXT_PUBLIC_NODE_ENV ??
  serverRuntimeConfig?.NEXT_PUBLIC_NODE_ENV ??
  null;

export const NEXT_PUBLIC_USE_BASELINE_SEO_DEFAULT =
  publicRuntimeConfig?.NEXT_PUBLIC_USE_BASELINE_SEO_DEFAULT ??
  serverRuntimeConfig?.NEXT_PUBLIC_USE_BASELINE_SEO_DEFAULT ??
  false;

export const NEXT_PUBLIC_BUILD_COMMIT_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_BUILD_COMMIT_ID ??
  serverRuntimeConfig?.NEXT_PUBLIC_BUILD_COMMIT_ID ??
  null;

export const NEXT_PUBLIC_MARKET_URL = `https://market.${NEXT_PUBLIC_DOMAIN_NAME}`;

export const EVENT_STREAM_DESTINATION_URL =
  NEXT_PUBLIC_NODE_ENV === "production"
    ? "https://live.virtualfes2023.flyingmilktea.com"
    : "https://event.ocgl.live";

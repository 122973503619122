import React, { createContext, useMemo } from "react";

import { IntlProvider } from "react-intl";

export const INITIAL_SETTINGS = {
  defaultLocale: "en",
};

const PageLocaleContext = createContext({
  ...INITIAL_SETTINGS,
  setLocale: () => {},
});

// ? there is RegionContext in market-webapp-commons, what are different what are common?
export const PageLocaleProvider = ({ locale, children }) => {
  const value = useMemo(
    () => ({
      locale,
    }),
    [locale]
  );

  return (
    <PageLocaleContext.Provider value={value}>
      {children}
    </PageLocaleContext.Provider>
  );
};

export const usePageLocale = () => React.useContext(PageLocaleContext);

export const LocalizationProvider = ({ translationTexts, children }) => {
  const { defaultLocale, locale } = usePageLocale();
  return (
    <IntlProvider
      messages={translationTexts[locale]}
      locale={locale}
      defaultLocale={defaultLocale ?? "en"}
    >
      {children}
    </IntlProvider>
  );
};
